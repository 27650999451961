import React, { Component } from 'react'
import { Tooltip } from '@mui/material';

import "./styles.css";
 
class Score extends Component {

  constructor(props) {
    super(props);
    this.state = {
      largeur: 0
    }
    this.barreRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ largeur: this.barreRef.current.offsetWidth });
  }

  render () {

    const { niveau, nombre } = this.props;
    const scorePx = this.state.largeur * niveau / 100;

    return (
      <Tooltip title={`${nombre}`} arrow placement="top">
        <div ref={this.barreRef} className="niveau-barre">
          <div
            className="niveau-barre-remplie"
            style={{ width: (scorePx < 1 && niveau > 0) ? '1px' : `${scorePx}px` }}
          />
        </div>
      </Tooltip>
    )
  }
}

export default Score;