import React, { Component } from 'react';
import { Slider } from '@mui/material';

/**
 * Composant Cursor permettant de rechercher du plus récent au plus pertinent
 */
class Cursor extends Component {

    /**
     * Fonction déclenchée au changement de position du curseur
     * @param {*} e : événement on Change
     * @param {*} value : position du curseur
     */
    handleChangeCursor(e, value) {
        //Appel de la fonction définie dans l'attribut handleChange présente dans la page Home.js 
        this.props.handleChange(value);
    }

    /**
     * Rendu du Composant Cursor
     * @returns : template HTML avec les différents appels aux variables passées en paramètre depuis la page Home.js et aux fonctions JS
     */
    render() {
        return (
            <div className='col-sm-12 col-md-6 d-flex justify-content-around align-items-center' style={{visibility: this.props.disabled ? 'hidden' : 'visible'}}>
                <div>Plus récent</div>
                {/* this.props.disable n'est plus pris en compte ci-dessous mais dans le bloc supérieur */}
                <Slider aria-label="Volume" value={this.props.valueSearch} onChangeCommitted={this.handleChangeCursor.bind(this)} sx={{ width: { xs: 150, md: 200, lg: 250 }, color: '#CA0538' }} min={0} max={2} valueLabelDisplay="off" step={1} disabled={this.props.disabled} />
                <div>Plus pertinent</div>
            </div>
        )
    }
}

export default Cursor;