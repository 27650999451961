/**
 * Appel vers l'API Spring Boot pour rechercher les documents ElasticSearch correspondants aux critères (serveur-serveur)
 * @param {*} search_query : Liste de mot(s)-clé(s)
 * @param {*} rescore_time : Valeur de la balance pertinence / récence (entre -10 et 10)
 * @param {*} listProfessions : Liste des professions sélectionnées
 * @param {*} listTypeDocs : Liste des types de documents sélectionnés
 * @param {*} page : Numéro de page de recherche
 * @returns : Réponse sous format JSON des données ElasticSearch (Nombre de résultats, Documents, Listes de professions et des types de documents)
 */
function searchES(search_query, rescore_time, listProfessions, listTypeDocs, page) {
    const request = fetch('/api/search?search_query=' + search_query + "&rescore_time=" + rescore_time + "&professions=" + listProfessions + "&types_doc=" + listTypeDocs + "&page=" + page, {
        method: 'GET',
    })

    return request.then(response => {
        if (response.status >= 400) {
            throw new Error("Server responds with error! " + response.statusText);
        }
        return response.json();
    })
}

/**
 * Appel direct à l'API Elastic pour la suggestion des mots-clés (client-serveur)
 * @param {*} postData : requête permettant de suggéer des expressions en fonction de la saisie de l'utilisateur
 * @returns : Réponse sous format JSON de la liste des suggestions
 */
function suggestES(postData) {
    const request = fetch('/' + window.env.REACT_APP_ELASTICSEARCH_INDEX + '/_search', {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify(postData)
    })

    return request.then(response => {
        if (response.status >= 400) {
            throw new Error("Server responds with error! " + response.statusText);
        }
        return response.json();
    })
}


export default { searchES, suggestES };