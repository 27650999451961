import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import Score from '../score/score';

import "./styles.css";

/**
 * Composant FacetteProfs permettant de construire la liste des facettes des professions
 */
class FacetteSelection extends Component {

    facetteClick(liste, id, type, e) {
        this.props.handleFacetteClick(liste, id, type, e);
    }

    professionCount(profession) {
        const element = this.props.homeToFacetteProfessions.find(elt => elt.key === profession.code);
        return element ? element.count : '';
    }

    typeDocumentsCount(typeDocument) {
        const element = this.props.homeToFacetteTypeDocuments.find(elt => elt.key === typeDocument.name);
        return element ? element.count : '';
    }

    render() {

        const hasCheckedElements = this.props.listTypeDocuments.some(elt => elt.checked) || this.props.listProfessions.some(elt => elt.checked);

        if (!hasCheckedElements) {
            return <div></div>;
        }

        const scoreMaxTypeDocuments = Math.max(...this.props.homeToFacetteTypeDocuments.map(obj => obj.count));
        const scoreMaxProfessions = Math.max(...this.props.homeToFacetteProfessions.map(obj => obj.count));

        return (
            <div>
                        <div>
                            <table><tbody>
                            {this.props.listTypeDocuments.filter(elt => elt.checked).map(typeDocument => {
                                    return (
                                        <tr key={typeDocument.code}>
                                            <td className='nom'>
                                                <FormGroup>
                                                    <FormControlLabel control={<Checkbox checked={typeDocument.checked} onChange={this.facetteClick.bind(this, this.props.listTypeDocuments, typeDocument.code, "typeDocuments")} name={typeDocument.code} size='small' sx={{
                                                        '&.Mui-checked': {
                                                            color: '#CA0538',
                                                        },
                                                        paddingTop: '0px',
                                                        paddingBottom: '0px'
                                                    }} />} label={typeDocument.name} 
                                                    />
                                                </FormGroup>
                                            </td>
                                            <td className='score'>
                                                <Score niveau={ 100 * this.typeDocumentsCount(typeDocument) / scoreMaxTypeDocuments } nombre={ this.typeDocumentsCount(typeDocument) }></Score>
                                            </td>
                                        </tr>
                                    )
                                })}  
                                {this.props.listProfessions.filter(elt => elt.checked).map(profession => {
                                    return (
                                        <tr key={profession.code}>
                                            <td className='nom'>
                                                <FormGroup>
                                                    <FormControlLabel control={<Checkbox checked={profession.checked} onChange={this.facetteClick.bind(this, this.props.listProfessions, profession.code, "professions")} name={profession.code} size='small' sx={{
                                                        '&.Mui-checked': {
                                                            color: '#CA0538',
                                                        },
                                                        paddingTop: '0px',
                                                        paddingBottom: '0px'
                                                    }} />} label={profession.name} 
                                                    />
                                                </FormGroup>
                                            </td>
                                            <td className='score'>
                                                <Score niveau={ 100 * this.professionCount(profession) / scoreMaxProfessions } nombre={ this.professionCount(profession) }></Score>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody></table>
                        </div>
       
            </div>
        )
    }
}

export default FacetteSelection;