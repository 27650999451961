import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";

import "./styles.css";
import logoCetim from "../../assets/images/logo_cetim_carnot.svg";

import { env } from '../../env'

// Fonction récursive pour afficher les liens
const LinkTree = ({ links }) => {
  if (!links) {
    return (
      <div></div>
    );
  }

  return (
    <ul>
      {links.map((link, index) => (
        <li key={index} className={link.child_menu ? "has-children" : ""}>
          <a href={link.link}>{link.title}</a>
          {link.child_menu && <LinkTree links={link.child_menu} />}
        </li>
      ))}
    </ul>
  );
};

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      json: null, // On initialise avec null pour savoir si les données sont chargées
      error: null,
      menuVisible: false,
      urlHelloIDConnect: '/api/login'
    };
  }

  componentDidMount() {
    // console.log('componentDidMount');
    this.getHeader()
      .then((data) => this.setState({ json: data.data })) // On récupère juste le contenu utile
      .catch((error) => this.setState({ error }));
  }

  toggleMenu = () => {
    this.setState((prevState) => ({ menuVisible: !prevState.menuVisible }));
  };


  render() {
    // console.log('render');
    const { showConnexion } = this.props;
    const { json, error, menuVisible: isMenuOpened, urlHelloIDConnect } = this.state;

    if (error) {
      return <div></div>;
    }

    if (json === null) {
      return <div></div>; // Affichage pendant le chargement
    }

    return (
      <>
        <nav className="navbar navbar-expand-lg bg-body-tertiary largeur-100">
          <div className="container-fluid largeur-100">
            {!isMenuOpened ? (
              <div onClick={this.toggleMenu} style={{ cursor: "pointer" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  className="bi bi-list"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                  />
                </svg>
              </div>
            ) : (
              <div onClick={this.toggleMenu} style={{ cursor: "pointer" }}>
                <FontAwesomeIcon
                  icon={faXmark}
                  style={{ fontSize: 30, color: "red" }}
                />
              </div>
            )}

            <div id="navbarSupportedContent">
              <div className="container-fluid">
                <div className="container d-flex justify-content-center">
                  <a className="navbar-brand" href="http://www.cetim.fr">
                    <img src={logoCetim} alt="Logo CETIM" height="24" />
                  </a>
                </div>
              </div>
            </div>
            {showConnexion && (
            <a className="navbar-brand" href={urlHelloIDConnect}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-person d-block align-text-bottom"
                style={{ margin: "auto" }}
                viewBox="0 0 16 16"
              >
                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
              </svg>
              <span style={{ fontSize: "60%" }}>Connexion</span>
            </a>)}
          </div>
        </nav>

        <div className="container-fluid">
          <div className="row">
            <div
              className={`menu-container row ${
                isMenuOpened ? "menu-open" : "menu-closed"
              }`}
            >
              <div className="col-md-1"></div>
              <div className="col-sm-12 col-md-3">
                <LinkTree links={json["column1-menu"]} />
              </div>
              <div className="col-sm-12 col-md-3">
                <LinkTree links={json["column2-menu"]} />
              </div>
              <div className="col-sm-12 col-md-3">
                <LinkTree links={json["column3-menu"]} />
              </div>
              <div className="col-sm-12 col-md-2 column4-menu">
                <LinkTree links={json["column4-menu"]} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  getHeader() {
    const headerUrl = '/__internal/megamenu/\?outputype=raw';
    // console.log('Fetching header at: ' + headerUrl);
    return fetch(headerUrl, {
      method: "GET",
      headers: {
        'Accept': 'application/json'
      },
    })
      .then((response) => {
        if (response.status >= 400) {
          throw new Error("Server responds with error! " + response.statusText);
        }
        // console.log("Header fetched successfully!");
        return response.json();
      })
      .catch((error) => {
        console.error("Fallback on hardcoded header because of error while fetching header:", error);
        return {
          data: {
            "column1-menu": [
              {
                id: 948,
                menu_id: 0,
                link: "https://www.cetim.fr/mecatheque",
                title: "Mécathèque, la base de connaissances",
                child_menu: [
                  {
                    id: 949,
                    menu_id: 100,
                    link: "https://www.cetim.fr/mecatheque/Programme-R-D",
                    title: "Programme R&D",
                  },
                  {
                    id: 950,
                    menu_id: 101,
                    link: "https://www.cetim.fr/mecatheque/Toute-la-richesse-des-etudes",
                    title: "Résultats d’étude",
                  },
                  {
                    id: 951,
                    menu_id: 102,
                    link: "https://www.cetim.fr/mecatheque/Rechercher/Toute-la-base",
                    title: "Base documentaire",
                  },
                  {
                    id: 952,
                    menu_id: 103,
                    link: "https://www.cetim.fr/mecatheque/Start-ups",
                    title: "Annuaire des startups",
                  },
                ],
              },
              {
                id: 953,
                menu_id: 1,
                link: "https://www.cetim.fr/prestations",
                title: "Toutes nos solutions et prestations",
                child_menu: [
                  {
                    id: 954,
                    menu_id: 100,
                    link: "https://www.cetim.fr/prestations/essais-controles-mesures",
                    title: "Essais – contrôles – mesures",
                  },
                  {
                    id: 955,
                    menu_id: 101,
                    link: "https://www.cetim.fr/prestations/ingenierie-produits-procedes",
                    title: "Ingénierie produits / procédés",
                  },
                  {
                    id: 956,
                    menu_id: 102,
                    link: "https://www.cetim.fr/prestations/conseil-et-expertises",
                    title: "Conseil et Expertises",
                  },
                  {
                    id: 1385,
                    menu_id: 103,
                    link: "https://www.cetim.fr/expertises/l-analyse-de-defaillance/",
                    title: "Analyse de défaillance",
                  },
                  {
                    id: 957,
                    menu_id: 104,
                    link: "https://www.cetim.fr/temoignages",
                    title: "Témoignages Clients",
                  },
                ],
              },
              {
                id: 958,
                menu_id: 2,
                link: "https://www.cetim.fr/formation",
                title: "Nos formations Cetim Academy®",
                child_menu: [
                  {
                    id: 959,
                    menu_id: 100,
                    link: "https://www.cetim.fr/formation/Formation",
                    title: "Thématiques",
                  },
                  {
                    id: 1277,
                    menu_id: 101,
                    link: "https://www.cetim.fr/formation/Briques-technologiques",
                    title: "Briques technologiques",
                  },
                  {
                    id: 1278,
                    menu_id: 102,
                    link: "https://www.cetim.fr/formation/Chaines-de-valeur",
                    title: "Chaînes de valeur",
                  },
                  {
                    id: 960,
                    menu_id: 103,
                    link: "https://www.cetim.fr/formation/Formations-qualifiantes-certifiantes",
                    title: "Qualifiantes / certifiantes",
                  },
                  {
                    id: 961,
                    menu_id: 104,
                    link: "https://www.cetim.fr/formation/Parcours-de-specialisation",
                    title: "Parcours de spécialisation",
                  },
                  {
                    id: 962,
                    menu_id: 105,
                    link: "https://www.cetim.fr/formation/search?simple_search%5Bvirtual%5D=1&simple_search%5BsortClause%5D=relevance",
                    title: "A distance",
                  },
                  {
                    id: 963,
                    menu_id: 106,
                    link: "https://www.cetim.fr/formation/Cetim-Academy/Notre-offre/Formations-a-l-international",
                    title: "A l'international",
                  },
                ],
              },
            ],
            "column2-menu": [
              {
                id: 964,
                menu_id: 3,
                link: "https://www.cetim.fr/logiciels",
                title: "Nos logiciels",
                child_menu: [
                  {
                    id: 965,
                    menu_id: 100,
                    link: "https://www.cetim.fr/logiciels/?category=Logiciels%20m%C3%A9tiers",
                    title: "Logiciels métiers",
                  },
                  {
                    id: 966,
                    menu_id: 101,
                    link: "https://www.cetim.fr/logiciels/?category=Logiciels%20de%20calcul",
                    title: "Logiciels de calcul",
                  },
                  {
                    id: 967,
                    menu_id: 102,
                    link: "https://www.cetim.fr/logiciels/?category=Aide%20au%20chiffrage",
                    title: "Aide au chiffrage",
                  },
                  {
                    id: 1151,
                    menu_id: 103,
                    link: "https://www.cetim.fr/logiciels/?category=Bases%20de%20donn%C3%A9es",
                    title: "Bases de données",
                  },
                ],
              },
              {
                id: 968,
                menu_id: 4,
                link: "",
                title: "Appui à l’industrie",
                child_menu: [
                  {
                    id: 969,
                    menu_id: 100,
                    link: "https://www.cetim.fr/programmes-regionaux",
                    title: "Programmes régionaux",
                  },
                  {
                    id: 970,
                    menu_id: 101,
                    link: "https://www.cetim.fr/appui-industrie-normalisation",
                    title: "Normalisation",
                  },
                  {
                    id: 971,
                    menu_id: 102,
                    link: "https://www.tpm2025.fr/",
                    title: "Technologies Prioritaires 2025",
                  },
                ],
              },
              {
                id: 972,
                menu_id: 5,
                link: "",
                title: "Recherche",
                child_menu: [
                  {
                    id: 973,
                    menu_id: 100,
                    link: "https://www.cetim.fr/laboratoires-communs/",
                    title: "Laboratoires communs",
                  },
                  {
                    id: 974,
                    menu_id: 101,
                    link: "https://www.cetim.fr/institut-carnot",
                    title: "Carnot",
                  },
                  {
                    id: 975,
                    menu_id: 102,
                    link: "https://www.fondationdefrance.org/fr/annuaire-des-fondations/fondation-cetim",
                    title: "Fondation Cetim",
                  },
                  {
                    id: 976,
                    menu_id: 103,
                    link: "https://www.cetim.fr/mecatheque/Publications-scientifiques-et-techniques",
                    title: "Publications scientifiques",
                  },
                  {
                    id: 977,
                    menu_id: 104,
                    link: "https://www.cetim.fr/librairie/",
                    title: "Librairie",
                  },
                ],
              },
            ],
            "column3-menu": [
              {
                id: 978,
                menu_id: 6,
                link: "https://www.cetim.fr/reconnaissances",
                title: "Agréments et reconnaissances QSE",
                child_menu: [
                  {
                    id: 979,
                    menu_id: 100,
                    link: "https://www.cetim.fr/reconnaissances/certification-qualite/",
                    title: "Certifications qualité",
                  },
                  {
                    id: 980,
                    menu_id: 101,
                    link: "https://www.cetim.fr/reconnaissances/cofrac-etalonnage/",
                    title: "Cofrac Étalonnage",
                  },
                  {
                    id: 981,
                    menu_id: 102,
                    link: "https://www.cetim.fr/reconnaissances/cofrac-essai/",
                    title: "Cofrac Essai",
                  },
                  {
                    id: 982,
                    menu_id: 103,
                    link: "https://www.cetim.fr/reconnaissances/mase/",
                    title: "MASE",
                  },
                  {
                    id: 983,
                    menu_id: 104,
                    link: "https://www.cetim.fr/reconnaissances/notifications-ce/",
                    title: "Notifications CE",
                  },
                  {
                    id: 984,
                    menu_id: 105,
                    link: "https://www.cetim.fr/reconnaissances/agrements-internationaux/",
                    title: "Agréments internationaux",
                  },
                  {
                    id: 1111,
                    menu_id: 106,
                    link: "https://www.cetim.fr/reconnaissances/agrement-ministeriel/",
                    title: "Agrément ministériel",
                  },
                  {
                    id: 985,
                    menu_id: 107,
                    link: "https://www.cetim.fr/reconnaissances/certifications-cofrend/",
                    title: "Certifications Cofrend",
                  },
                ],
              },
              {
                id: 986,
                menu_id: 7,
                link: "",
                title: "Qui sommes-nous ?",
                child_menu: [
                  {
                    id: 987,
                    menu_id: 100,
                    link: "https://www.cetim.fr/presentation",
                    title: "Le Cetim en bref",
                  },
                  {
                    id: 1706,
                    menu_id: 101,
                    link: "https://www.cetim.fr/nos-valeurs/",
                    title: "Nos valeurs",
                  },
                  {
                    id: 988,
                    menu_id: 102,
                    link: "https://www.cetim.fr/gouvernance",
                    title: "Gouvernance",
                  },
                  {
                    id: 989,
                    menu_id: 103,
                    link: "https://www.cetim.fr/rapports-publications/",
                    title: "Rapports - Publications",
                  },
                  {
                    id: 1527,
                    menu_id: 104,
                    link: "https://www.cetim.fr/video-de-presentation/",
                    title: "Vidéo de présentation",
                  },
                  {
                    id: 990,
                    menu_id: 105,
                    link: "https://www.cetim.fr/historique",
                    title: "Historique",
                  },
                  {
                    id: 991,
                    menu_id: 106,
                    link: "https://www.cetim.fr/charte-developpement-durable",
                    title: "Charte développement durable",
                  },
                  {
                    id: 992,
                    menu_id: 107,
                    link: "https://www.cetim.fr/egalite-femmes-hommes",
                    title: "Égalité Femmes/Hommes",
                  },
                ],
              },
            ],
            "column4-menu": [
              {
                id: 993,
                menu_id: 8,
                link: "https://www.cetim.fr/agenda",
                title: "AGENDA",
              },
              {
                id: 994,
                menu_id: 9,
                link: "https://www.cetim.fr/actualites",
                title: "ACTUALITÉS",
              },
              {
                id: 995,
                menu_id: 10,
                link: "https://www.youtube.com/user/CetimFrance",
                title: "VIDÉOS",
              },
              {
                id: 996,
                menu_id: 11,
                link: "https://www.cetim.fr/implantations",
                title: "IMPLANTATIONS",
              },
              {
                id: 997,
                menu_id: 12,
                link: "https://www.cetim.fr/nous-rejoindre",
                title: "NOUS REJOINDRE",
              },
              {
                id: 998,
                menu_id: 13,
                link: "#",
                title: "NOUS CONTACTER",
              },
            ],
          },
          itemsCount: 4,
          status: "success",
          code: 200,
        };
      });
  }
}

export default Header;
