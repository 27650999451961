import React, { Component } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faComment } from '@fortawesome/free-solid-svg-icons';

/**
 * Composant Recherche contenant une barre (avec auto-complétion) et un bouton de recherche
 */
class Search extends Component {
    /**
     * Toutes les variables dont a besoin le Composant et qui seront modifiées à travers l'application (variables d'état)
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.onKeyDown = this.onKeyDown.bind(this);
        this.handleClick = this.handleClick.bind(this);

        this.state = {
            results: [],
            searchResult: true,
            value: props.initialValue || []
        }
    }

    componentDidMount() {
        fetch('/api/connect/status')
        .then(response => {
            if (response.status !== 200) {
                // console.log('User pbbt pas encore connecté');
                return response.text();
            }
            return response.json();
        })
        .then(content => {
            if (content.connectStatus === 2) {
                this.setState({ showIa: true });
            }
          })
          .catch(error => console.error('Error fetching data:', error));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.initialValue !== this.props.initialValue) {
            this.setState({ value: this.props.initialValue });
        }
    }

    /**
     * Fonction déclenchée au moment de la saisie d'un mot-clé dans la barre de recherche
     * @param {*} e : événement onInputChange
     * @param {*} value : valeur saisie par l'utilisateur dans la barre de recherche
     */
    onValueChangeSearch(e) {
        const value = e.target.value; // Accéder à la valeur de l'entrée
        //Appel de la fonction définie dans l'attribut onValueChange présente dans la page Home.js
        this.props.onValueChange(e, value);
    };

    onKeyDown(e) {
        if (e.key === 'Enter') {
            this.handleClick(e);
        }
    }
    
    /**
     * Fonction déclenchée au moment de la recherche de fiches Mécathèque grâce au bouton de recherche
     * @param {*} e :  événement onClick
     */
    handleClick(e) {
        //Appel de la fonction définie dans l'attribut handleClick présente dans la page Home.js
        this.props.handleClick();
    }

    handleClickIA(e) {
        window.open('https://mecatheque.ai.cetim.fr/', '_blank');
    };

    /**
     * Fonction déclenchée au moment de la sélection de mots-clés
     * @param {*} e : évement onChange
     * @param {*} value : liste des mots-clés sélectionnées
     */
    selectValue(e, value) {
        //Appel de la fonction définie dans l'attribut selectValue présente dans la page Home.js
        this.props.selectValue(value);
    }

    /**
     * Rendu du Composant de recherche
     * @returns : template HTML avec les différents appels aux fonction JS
     */
    render() {
        // Permet d'accepter la case (sensitive : false, insensitive : true)
        // A mettre dans le composant Autcomplete ; filterOptions={filterOptions}
        const filterOptions = createFilterOptions({
            ignoreAccents: true,
            ignoreCase: true,
            //limit: 7,
        });

        return (
            <div className='col-sm-12 col-md-6 d-flex align-items-center'>
            <Autocomplete
                id="free-solo-demo"
                freeSolo
                onInputChange={this.onValueChangeSearch.bind(this)}
                onChange={this.selectValue.bind(this)}
                onKeyDown={this.onKeyDown}
                filterOptions={filterOptions}
                multiple
                value={this.state.value} // Utiliser la valeur de l'état
                sx={{ width: 500 }}
                options={this.props.resultsES.map(option => option._source.expression)}
                renderInput={(params) => <TextField {...params} label="Rechercher" sx={{
                "& label.Mui-focused": {
                    color: '#CA0538'
                },
                "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                    borderColor: '#CA0538'
                    }
                }
                }} />}
            />
            <button className="btn btn-primary bouton-recherche" onClick={this.handleClick.bind(this)} style={{ minWidth: 50 }}>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
            {this.state.showIa && (
                <button style={{ marginLeft: 10, minWidth: 50 }} className="btn btn-primary bouton-recherche" onClick={this.handleClickIA.bind(this)}>
                    <img src="./images/picto-ia.png" alt="IA Icon" style={{ height: '100%', margin: '-10px' }} />
                </button>
            )}
            </div>
        )
    }
}

export default Search;