import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './pages/home/Home';
import Fiche from './pages/fiche/Fiche';
import Navbar from './components/navbar/Navbar';
import './App.css';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Ia from './components/ia/Ia';

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
          showIa: false,
          showConnexion: true,
          email: '',
          numInterloc: ''
        };
      }

    componentDidMount() {
        fetch('/api/connect/status')
            .then(response => {
                if (response.status !== 200) {
                    // console.log('User non connecté ou non connu')
                    return response.text();
                }
                return response.json();
            })
            .then(content => {
              if (content.connectStatus !== undefined && (content.connectStatus === 1 || content.connectStatus === 2)) {
                this.setState({ showConnexion: false });
              }
              if (content.connectStatus !== undefined && content.connectStatus === 2) {
                  this.setState({ showIa: true });
              }
              if (content.email !== undefined) {
                this.setState({ email: content.email });
              }
              if (content.numInterloc !== undefined && content.numInterloc !== 'NO_CRM_CONTACT_NUMBER') {
                this.setState({ numInterloc: content.numInterloc });
                window.matomo_ct_numrodinterlocuteur = content.numInterloc;
              }
            })
            .catch(error => console.error('Error fetching data:', error));
    }

  render() {
    return (
      <Router>
        <Ia show={this.state.showIa} email={this.state.email} />
        <Header showConnexion={this.state.showConnexion} />
        <Navbar />
        <div className='row container'>
      
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/fiche/:id' element={<Fiche />} />
            </Routes>

        </div>
        <Footer />
      </Router>
    );
  }
}
export default App;
